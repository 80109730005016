import DateTime from 'luxon/src/datetime.js'
import { resetAuthUser } from '@/services/auth';
import { getCurrencyPrefix } from '@/helpers/currency'
import moment from 'moment';

var dateFormat = require('dateformat');

export default {

  logout(page, returnToPath) {
    this.clearCookies(page)
    page.$cookies.set("returnToPath", returnToPath);
    resetAuthUser()
    window.location.href = returnToPath || '/'
  },

  dashboard() {
    window.location.href = process.env.VUE_APP_APP_URL + "/dashboard/BoxOffices"
  },

  async validate(form) {
    if (!await form.validate()) {
        let elementsInErrors = document.getElementsByClassName('error--text');
        if (elementsInErrors && elementsInErrors.length > 0) {
            elementsInErrors[0].scrollIntoView();
        }
        return false;
      };
      return true;
  },
  
  dateToIso(datetime) {
    if (datetime) {
      return DateTime.fromJSDate(new Date(datetime)).toString();
    }
  },

  dateToShortString(datetime) {
    if (datetime) {
      return dateFormat(new Date(datetime), "dd/mm/yyyy, h:MMtt");
    }
  },

  timezoneDateToShortString(datetime, timezone, format) {
    if (datetime) {
      return moment(datetime).tz(timezone || 'Europe/London').format(format || 'DD MMM YYYY h:mmA')
    }
    return ''
  },

  sameDay(dateOne, dateTwo) {
    if (dateOne && dateTwo) {
      if (this.dateToDdMmYyString(dateOne) == this.dateToDdMmYyString(dateTwo)) {
        return true;
      }
    }
    return false;
  },

  dateToDdMmYyString(datetime) {
    if (datetime) {
      return dateFormat(new Date(datetime), "dd/mm/yyyy");
    }
  },

  dateToNiceString(datetime) {
    if (datetime) {
      return dateFormat(new Date(datetime), "ddd dd mmm yyyy, h:MMtt");
    }
  },

  dateToDay(datetime) {
    if (datetime) {
      return dateFormat(new Date(datetime), "ddd");
    }
  },

  dateToNiceDay(datetime) {
    if (datetime) {
      return dateFormat(new Date(datetime), "ddd dd mmm yyyy");
    }
  },
  dateToNiceTime(datetime) {
    if (datetime) {
      return dateFormat(new Date(datetime), "h:MMtt");
    }
  },

  dateToDateTime(iso) {
    if (iso) {
      return new Date(iso).getTime();
    }
  },

  dateNow() {
    return new Date().getTime();
  },

  priceToString(price) {
    if (price) {
      return parseFloat(price).toFixed(2);
    }
  },

  priceDisplay(price, currency) {
    if (price) {
      return this.currencyDisplay(price, currency);
    } else {
      return "Free";
    }
  },
  
  numberWithCommas(val) {
    if (val !== 0 && !val) {
        return ''
    }
    return `${val && val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
  },

  formatPercent(value) {
    if (value) {  
      return this.priceToString(value * 100) + "%";
    }
  },

  currencyDisplay(amount, currency) {
    if (!amount) {
      return `${getCurrencyPrefix(currency)}0.00`;
    }
    return `${getCurrencyPrefix(currency)}${this.priceToString(amount)}`;
  },

  priceToNumber(price) {
    if (price) {
      return parseFloat(price);
    } else {
      return 0;
    }
  },

  jsonCopy(src) {
    return JSON.parse(JSON.stringify(src));
  },

  requiredRule(value) {
    if (value) {
      return true;
    } 
    return 'Required.';
  },

  requiredRuleNoSpaces(value) {
    if (!value) {
      return 'Required.';
    } 
    if (/^[a-zA-Z0-9]+$/.test(value)) {
      return true;
    } 
    return 'Only letters and numbers please.';
  },

  integerRule(value) {
    if (/^\d+$/.test(value)) {
      return true;
    }
    return 'Must be a whole number.';
  },

  pinRule(value) {
    if (!value || /^[0-9]{6,6}$/.test(value)) {
      return true;
    }
    return 'Must be a six digit number.';
  },

  emailRule(value) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (pattern.test(value.trim())) {
      return true;
    }
    return 'Invalid e-mail.';
  },

  dateOfBirthRule(value) {
    if (!value) {
      return true;
    }
    const pattern = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/
    if (pattern.test(value.trim())) {
      return true;
    }
    return 'Please enter DD/MM/YYYY';
  },

  
  priceRule(value) {
    if (!value) {
      return true;
    }
    if (value < 0) {
      return 'cannot be negative';
    }
    value = value+"";
    if (value.match(/(?=.*?\d)^(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/)) {
      return true;
    }
    return 'Must be a valid amount.';
  },

  makeBoxOfficeURL(boxOffice) {
    if (boxOffice) {
      return window.location.origin + "/boxOffice/" + boxOffice.path;
    }
  },

  priceRuleAndCheckStripe(boxOffice, value) {
    var result = this.priceRule(value);
    if (result != true) {
      return result;
    }
    return this.donationRule(boxOffice, value)
  },

  donationRule(boxOffice, value) {
    if (!boxOffice || (!boxOffice.stripeId && value)) {
      return 'please connect your stripe account first';
    }
    return true;
  },
  
  clearCookies(page) {
    page.$cookies.remove("eventId");
    page.$cookies.remove("boxOfficeId");
    page.$cookies.remove("ticketTypeId");
    page.$cookies.remove("eventTimeId");
    page.$cookies.remove("wizardTab");
    page.$cookies.remove("uauth2");
    page.$cookies.remove("artistId");
  },
}

export const commonValidationRules = {
  descriptionRule: [
    v => !!v || "This field is required",
    v =>
      (v && v.length >= 6 && v.length <= 128) ||
      "This field must be at least 6 and maximum 128 characters"
  ],
  requiredRules: [
      v => !!v || "This field is required"
  ],
  requiredArrayRules: [
      v => !!v && !!v.length || "This field is required"
  ],
  minLengthTenChars: [
      v => !!v || "This field is required",
      v => (v && v.length >= 10) || "This field must be at least 10 characters"
  ],
  emailIdRules: [
    v => !!v || "E-mail is required",
    v => /.+@.+\..+/.test(v) || "E-mail must be valid"
  ],
  urlRules: [
      v => !!v || "This field is required",
      v => /^[a-zA-Z0-9\\-]+$/g.test(v) || "Special characters, or spaces not allowed" 
  ],
  urlRulesStrict: [
      v => !!v || "This field is required",
      v => /^[a-z0-9\\-]+$/g.test(v) || "Only lowercase letters, numbers, and hyphens allowed" 
  ],
  urlValidRules: [
    (v) => !v || /^https?:\/\//.test(v) || 'URL must start with http:// or https://',
    (v) =>
      !v ||
      /^(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]*[A-Za-z0-9+&@#/%=~_|]$/.test(v) ||
      'Not a valid URL',
  ],
  emailRules: [
    v => (!v || (/.+@.+\..+/.test(v))) || "E-mail must be valid"
  ],
  openstageEmailRules: [
    v => !!v || "E-mail is required",
    v => /.+@openstage.live/.test(v) || "E-mail must be for openstage.live domain"
  ],
  domainRules: [
    v => !!v || "Name is required",
    v => (v && v.length < 64) || "This field must be no more than 63 characters"
  ],
  passwordRules: [
      v => !!v || "Password is required",
      v => (v && v.length >= 8) || "Password must be at least 8 characters",
      v => (!(/\s+/.test(v))) || "Spaces not allowed"
  ],
  tagRules: [
      v => (v && v.length === 0)
          || (v && v.length >= 0 && /^[\\.a-zA-Z0-9_\\-]+$/g.test(v))
          || "Only letters, numbers, hyphens, dots, and underscores allowed"
  ],
  phoneNumberRules: [
      v => !v || /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im.test(v) || "Phone number must be valid"
  ],
  phoneNumberRulesCC: [
      v => {
          const number = v || ''
          if (!number) {
              return true
          }
          if(number.charAt(0) !== '+') {
              return "Phone number must start with +"
          }
          if(/\s/g.test(number)) {
              return "Phone number must not include any spaces"
          }
          if(!/^\+[0-9]+$/.test(number)) {
              return "Phone number must include digits only"
          }
          const parsedNumber = parsePhoneNumber(number)
          if (!parsedNumber || !number.includes('+')) {
              return "Phone number must be valid"
          }
          const isValid = isValidPhoneNumber(parsedNumber.number)
          return isValid || "Phone number must be valid"
      }
  ],
  phoneNumberRulesUS: [
      v => {
          const number = v || ''
          if (!number) {
              return true
          }
          if(number.substr(0,2) !== '+1') {
              return "Phone number must start with +1"
          }
          if(/\s/g.test(number)) {
              return "Phone number must not include any spaces"
          }
          if(!/^\+[0-9]+$/.test(number)) {
              return "Phone number must include digits only"
          }
          const parsedNumber = parsePhoneNumber(number)
          if (!parsedNumber || !number.includes('+1')) {
              return "Phone number must be valid"
          }
          const isValid = isValidPhoneNumber(parsedNumber.number)
          return isValid || "Phone number must be valid"
      }
  ],
  noBlankRules : [
      v => /^\S*$/.test(v) || "No spaces allowed"
  ],
  noBlankAndSpecialRules : [
      v => !!v || "This field is required",
      v => /^[A-Za-z0-9]+$/.test(v) || "You can use only alphanumeric characters"
  ],
  latRules: [
      v => !!v || "This field is required",
      v => (v <= 90 && v >= -90) || "Latitude range is -90 to 90"
  ],
  lngRules: [
      v => !!v || "This field is required",
      v => (v <= 180 && v >= -180) || "Longitude range is -180 to 180"
  ],
  iso2Rules: [
      v => !!v || "This field is required",
      v => v.length===2 || "ISO2 must have 2 characters",
  ],
  spotifySongIdRules: [
      v => (!v || v.startsWith("https://") || "Spotify URL should start with \"https://\""),
      v => (!v || v.startsWith("https://open.spotify.com/track/") || v.startsWith("https://open.spotify.com/album/")) 
          || "Spotify track or album URL should look like: https://open.spotify.com/track/2PzU4IB8Dr6mxV3lHuaG34 or https://open.spotify.com/album/7aOtKCNfEgLS90QUAovBk3"
  ],
  spotifyPlaylistIdRules: [
      v => (!v || v.startsWith("https://") || "Spotify URL should start with \"https://\""),
      v => (!v || v.startsWith("https://open.spotify.com/playlist/"))
          || "Spotify playlist URL should look like: https://open.spotify.com/playlist/6lAShLbKlrgszYmC7xjqM1"
  ],
  appleSongIdRules: [
      v => (!v || v.startsWith("https://") || "Spotify URL should start with \"https://\""),
      v => (!v || v.startsWith("https://music.apple.com/") && v.includes('/album/')) 
          || "Apple Music track or album URL should look like: https://music.apple.com/us/album/hot-rocks-1964-1971/1440764786"
  ],
  applePlaylistIdRules: [
      v => (!v || v.startsWith("https://") || "Spotify URL should start with \"https://\""),
      v => (!v || v.startsWith("https://music.apple.com/") && v.includes('/playlist/'))
          || "Apple Music playlist URL should look like: https://music.apple.com/us/playlist/classic-rock-essentials/pl.1a7fd42205674dd282d106f533f4bea6"
  ],
  deezerSongIdRules: [
      v => (!v || v.startsWith("https://") || "Deezer URL should start with \"https://\""),
      v => (!v || v.startsWith("https://www.deezer.com/") && (v.includes("/track") || v.includes("/album"))) 
          || "Deezer track or album URL should look like: https://www.deezer.com/us/track/7677778"
  ],
  deezerPlaylistIdRules: [
      v => (!v || v.startsWith("https://") || "Deezer URL should start with \"https://\""),
      v => (!v || v.startsWith("https://www.deezer.com/") && v.includes("/playlist")) 
          || "Deezer playlist URL should look like: https://www.deezer.com/us/playlist/1479458365"
  ],
  spotifyArtistUrlRules: [
      v => (!v || v.startsWith("https://") || "Spotify artist URL should start with \"https://\""),
      v => (!v || v.startsWith("https://open.spotify.com/") && v.includes("/artist/")) 
          || "Spotify artist URL should look like: https://open.spotify.com/artist/3w6zswp5THsSKYLICUbDTZ"
  ],
  appleArtistUrlRules: [
      v => (!v || v.startsWith("https://") || "Apple artist URL should start with \"https://\""),
      v => (!v || v.startsWith("https://music.apple.com/") && v.includes("/artist")) 
          || "Apple artist URL should look like: https://music.apple.com/gb/artist/gabrielle-aplin/434657397"
  ],
  deezerArtistUrlRules: [
      v => (!v || v.startsWith("https://") || "Deezer artist URL should start with \"https://\""),
      v => (!v || v.startsWith("https://www.deezer.com/") && v.includes("/artist/")) 
          || "Deezer artist URL should look like: https://www.deezer.com/us/artist/516830"
  ],
  discordUrlRules: [
      v => (!v || v.startsWith("https://") || "Discord Webook URL should start with \"https://\""),
      v => (!v || v.startsWith("https://discord.com/") && v.includes("/webhooks/")) 
          || "Discord Webook URL should look like: https://discord.com/api/webhooks/123456/UDhDFI0sV..."
  ],
  discordGuildIdRules: [
      v => (!v || /^[0-9]{18,}$/.test(v)) || "Discord Guild Id must be numbers only and at least 18 digits long",
  ],
  ageRestrictionRules: [
      v => ( !v || v >= 13 ) || "Required minimum age is 13",
  ],
  moneyStringRules: [
      v => (!v || /^(0|[1-9]\d*)(\.\d{1,2})?$/.test(v) || "Please use numbers (maximum 2 decimals)")
  ],
}